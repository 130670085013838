<template>
    <v-container>

        <v-card class="mt-5 darkTable table-card">

            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                class="mr-3 green"
                icon
                @click="exportExcel"
                >
                    <v-icon class="white--text">mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-text-field
                    v-model="searchDtCarInfo"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                    dense
                    class="shrink"
                ></v-text-field>
            </v-card-title>

            <v-data-table
            id="dtCarBook"
            class="sizingTable"
            :items-per-page="10"
            :headers="headerCarInfo"
            :items="filteredData"
            :search="searchDtCarInfo"
            :loading="loadingDataTable"
            :loading-text="loadingText"
            :custom-sort="customSort"
            >
                <template v-for="(header, i)  in headerCarInfo" v-slot:[`header.${header.value}`]="{  }"> 
                    <span @click.stop :key="i">
                        <v-text-field :key="i"
                        v-model="searchObj[header.value]"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        style="font-weight: 100;"
                        :disabled="isDisable(i)"
                        ></v-text-field>
                        {{ header.text }}
                    </span>
                </template>

                <template v-slot:[`item.image`]="{ item }">
                    <v-card
                    class="ma-4 text-left"
                    height="auto"
                    elevation="0"
                    width="150"
                    >
                        <v-img tile height="80" class="mt-2" :src="item.image"></v-img>
                    </v-card>
                </template>

                <template v-slot:[`item.detailsAll`]="{ item }">
                    <v-icon
                    color="primary"
                    dense
                    v-html="item.detailsAll"
                    @click="openFileDoc(item)"
                    >
                    </v-icon>
                </template>

                <template v-slot:[`item.dateMileage`]="{ item }">
                    <span v-html="convertDateTime(item.dateMileage)"></span>
                </template>

                

            </v-data-table>
        </v-card>

        <!-- Popup Details -->
        <template>
            <v-dialog
            v-model="dialogDetailsCar"
            persistent
            scrollable
            max-height="10px"
            max-width="800px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Car Booking Form</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <tr
                        v-for="item in displayDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td>

                                <template v-if="item.key == 'Image'">
                                    <v-card
                                    class="ma-4 text-left"
                                    height="auto"
                                    elevation="0"
                                    :width="$vuetify.breakpoint.lgAndUp? '400' : '150'"
                                    >
                                        <v-img tile class="mt-2" :src="item.value"></v-img>
                                    </v-card>
                                </template>

                                <span v-if="item.key != 'Image'">
                                    {{ item.value }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogDetailsCar = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

    </v-container>
</template>


<script>
import axios from 'axios';
import Papa from "papaparse";

export default {
    
    data: () => ({

        dataForExport: [],

        searchDtCarInfo: '',
        loadingDataTable: false,
        loadingText: '',

        searchObj: {},
        headerCarInfo: [
            {text: "Image", value: "image", sortable: false},
            {text: "Car", value: "name"},
            {text: "Plate Number", value: "plateno"},
            {text: "Latest Mileage", value: "latestMileage"},
            {text: "Date Car Used", value: "dateMileage"},
            {text: "Company", value: "company"},
            {text: "Detail", value: "detailsAll", sortable: false},
        ],
        allData: [],

        


        // Display Details Car
        displayDetails: [
            {key: "Image", value: "", icon: "mdi-camera-image"},    
            {key: "Name", value: "", icon: "mdi-car"},    
            {key: "Plate No.", value: "", icon: "mdi-numeric"},    
            {key: "Color", value: "", icon: "mdi-invert-colors"},    
            {key: "Company", value: "", icon: "mdi-domain"},    
            {key: "Type Use", value: "", icon: "mdi-car-clock"},    
            {key: "Latest Mileage", value: "", icon: "mdi-speedometer"},
            {key: "Date Mileage Updated", value: "", icon: "mdi-calendar"},
        ],
        dialogDetailsCar: false

    }),
    methods: {

        loadListCar(){

            this.loadingDataTable = true;
            this.allData = [];

            axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let data = response.data
                // console.log(data);

                // console.log(data.cartype);

                data.cartype.forEach(function(res){
                    res["name"] =  res["name"].split(" -")[0] + ")";
                    res["realColorCar"] =  res["name"].split("(")[1].split(")")[0];
                    res["mileage"] =  '';
                    res["company"] = '';
                    res["detailsAll"] = "mdi-file-document";

                    
                    res["car"] =  res["name"].split(" -")[0].split(" (")[0];
                    res["plateNo"] =  res["plateno"];
                    res["colorCar"] =  res["name"].split("(")[1].split(")")[0];

                    if (res["latestMileage"] != null) {
                        res["latestMileage"] = res["latestMileage"] + " KM";
                    }
                })

                for(let i in data.cartype){
                    for(let x in this.globalListCar){
                        if (data.cartype[i].plateno == this.globalListCar[x].plate) {
                            data.cartype[i].image = this.globalListCar[x].src;
                            data.cartype[i].company = this.globalListCar[x].company;
                        }
                    }

                    // if (data.cartype[i].image == "") {
                    //     this.globalListCar[0].src;
                    // }

                }

                let filterCarUsedOnly = [];
                // console.log(data.cartype);
                for(let i in data.cartype){
                    if (data.cartype[i]['plateNo'] != "VP 2514") {
                        filterCarUsedOnly.push(data.cartype[i]);
                    }
                }


                

                // this.allData = data.cartype;
                this.allData = filterCarUsedOnly;
                // console.log(this.allData);
                this.loadingDataTable = false;
                this.loadingText = "";
                
            })
            .catch(error => {
                console.log(error.response);
                this.loadingDataTable = false;
                this.loadingText = "";
            })
        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            this.dataForExport = items;

            items.sort((a, b) => {
                if (index[0]=='dateMileage') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },
        
        isDisable(index) {
            // console.log(index);
            if (index == 0) {
                return true;
            }
            else if (index == 6) {
                return true;
            }
            else{
                return false;
            }
        },

        openFileDoc(item){
            // console.log(item);

            this.displayDetails[0].value = item.image;
            this.displayDetails[1].value = item.name.split(" (")[0];
            this.displayDetails[2].value = item.plateno;
            this.displayDetails[3].value = item.realColorCar;
            this.displayDetails[4].value = item.company;
            this.displayDetails[5].value = item.type;
            this.displayDetails[6].value = item.latestMileage;
            // if (item.latestMileage != null && item.latestMileage != "") {
            //     this.displayDetails[6].value = item.latestMileage + " KM"; 
            // }
            // else{
            //     this.displayDetails[6].value = item.latestMileage;
            // }
            this.displayDetails[7].value = this.convertDateTime(item.dateMileage);

            this.dialogDetailsCar = true;
        },

        exportExcel(){

            // console.log(this.dataForExport);

            let keys = [
                "car",
                "colorCar",
                "plateNo",
                "company",
                "type",
                "mileage",
            ],
            resultAfterFilter = this.dataForExport.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

            let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");

            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'List of Booking Meeting Room.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    },
    mounted(){
        this.loadListCar();
    },
    computed: {
        filteredData(){
            if (this.searchObj) {
                return this.allData.filter(item => {
                    return Object.entries(this.searchObj).every(([key, value]) => {
                        return (item[key] ||'').toLowerCase().includes(value.toLowerCase())
                    })
                })
            }
            else{
                return this.allData
            } 
        },
    }
}

</script>

<style lang="scss">
@import '~scss/main';

</style>