<template>
    <v-container>

        <!-- Detail information -->
        <v-card>
            <v-card-text>
                <ul class="bullet-icon">
                    <li>
                    <v-icon class="primary--text">mdi-information</v-icon>
                    Booking cancellations cannot be canceled if the day of event has passed. For any issue regarding the car booking system, please contact HR.
                    </li>
                </ul>
            </v-card-text>
        </v-card>

        <!-- Details Table -->
        <v-card class="mt-5 darkTable table-card">

            <v-card-title>

                <v-row>
                    <v-col cols="12" md="4">
                        <v-select
                        :value="valueAll"
                        v-model="whatFilterValueApproval"
                        :items="itemsFilterApproval"
                        label="Car Approval"
                        outlined
                        dense
                        prepend-inner-icon="mdi-account"
                        @change="filterTable($event,'userApproval')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                        :value="valueAll"
                        v-model="whatFilterValueMileage"
                        :items="itemsFilterStatus"
                        label="Status Mileage Update"
                        outlined
                        dense
                        prepend-inner-icon="mdi-speedometer"
                        @change="filterTable($event,'statusMil')"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-spacer></v-spacer>

                <v-btn
                class="mr-3 green"
                icon
                @click="exportExcel"
                >
                    <v-icon class="white--text">mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-text-field
                    v-model="searchDtCarBook"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                    dense
                    class="shrink"
                ></v-text-field>
            </v-card-title>

            <v-data-table
            id="dtCarBook"
            class="sizingTable"
            :items-per-page="10"
            :headers="headerCarBooking"
            :items="filteredData"
            :search="searchDtCarBook"
            :loading="loadingDataTable"
            :loading-text="loadingText"
            :custom-sort="customSort"
            >
                <template v-for="(header, i)  in headerCarBooking" v-slot:[`header.${header.value}`]="{  }"> 
                    <span @click.stop :key="i">
                        <v-text-field :key="i"
                        v-model="searchObj[header.value]"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        style="font-weight: 100;"
                        :disabled="isDisable(i)"
                        ></v-text-field>
                        {{ header.text }}
                    </span>
                </template>
                    
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                    :color="controlBgColor(item.status)"
                    :class="controlColorFont(item.status)"
                    >
                    {{item.status}}
                    </v-chip>
                </template>
                    
                <template v-slot:[`item.statusMileageUpdated`]="{ item }">
                    
                    <span class="success--text" v-if="item.statusMileageUpdated == 'YES'">
                        <v-icon color="success" class="mr-2">
                            mdi-progress-check
                        </v-icon>
                        <strong>Updated</strong>
                    </span>

                    <span class="error--text" v-if="item.statusMileageUpdated == 'NO'">
                        <v-icon color="error" class="mr-2">
                            mdi-progress-alert
                        </v-icon>
                        <strong>Not Update</strong>
                    </span>

                    <span class="grey--text" v-if="item.statusMileageUpdated == 'Reject'">
                        <v-icon color="grey" class="mr-2">
                            mdi-progress-close
                        </v-icon>
                        <strong>Cancel</strong>
                    </span>
                    
                    <span class="pending--text" v-if="item.statusMileageUpdated == 'InUse'">
                        <v-icon color="pending" class="mr-2">
                            mdi-progress-clock
                        </v-icon>
                        <strong>Waiting..</strong>
                    </span>
                    
                    
                </template>

                <template v-slot:[`item.detailsAll`]="{ item }">
                    <v-icon
                    color="primary"
                    dense
                    v-html="item.detailsAll"
                    @click="openFileDoc(item)"
                    >
                    </v-icon>
                </template>

                

            </v-data-table>
        </v-card>

        <!-- Popup Details -->
        <template>
            <v-dialog
            v-model="dialogDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Car Booking Form</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <tr
                        v-for="item in displayDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td>
                                <span
                                v-if="item.value != 'APPROVED' && item.value != 'REJECTED' && item.value != 'PENDING'"
                                >
                                {{ item.value }}
                                </span>

                                <v-chip
                                v-if="item.value == 'APPROVED' || item.value == 'REJECTED' || item.value == 'PENDING'"
                                :color="controlBgColor(item.value)"
                                :class="controlColorFont(item.value)"
                                >
                                {{item.value}}
                                </v-chip>

                                <a v-if="item.key == 'Mileage Data Image' && item.path != null" :href="item.path" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="item.key == 'Mileage Data Image' && item.path == null" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a> 
                                
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-divider v-if="detailData != null && detailData.status == 'PENDING' && onlyAdmin"></v-divider>

                <v-card-actions class="py-2">
                    <span
                    v-if="detailData != null && detailData.status == 'PENDING' && onlyAdmin"
                    class="pl-7 body-2">
                        Car Use Approvement
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                    v-if="detailData != null && detailData.status == 'PENDING' && onlyAdmin"
                    class="text-bold"
                    color="reject"
                    outlined
                    @click="rejectPending(detailData)"
                    >
                        Reject
                    </v-btn>
                    <v-btn
                    v-if="detailData != null && detailData.status == 'PENDING' && onlyAdmin"
                    class="text-bold"
                    color="success"
                    outlined
                    @click="approvePending(detailData)"
                    >
                        Approve
                    </v-btn>
                </v-card-actions>

                <v-divider v-if="detailData != null && detailData.status == 'PENDING' && onlyAdmin"></v-divider>
                
                <v-card-actions class="pa-5">
                    <v-btn
                    class="text-bold"
                    color="red"
                    text
                    :disabled="xlehCancelBtn"
                    @click="openWarning(detailData)"
                    >
                        Cancel Booking
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                    class="text-bold"
                    color="primary"
                    text
                    @click="dialogDisplayDetails = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Cancel Booking Car -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="warningDelete"
                persistent
                max-width="300"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-delete-empty</v-icon>
                        Cancel Booking?
                    </v-card-title>
                    <v-card-text class="blackk--text">Are you sure want to cancel this booking?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="warningDelete = false;"
                    >
                        NO
                    </v-btn>
                    <v-btn
                        class="text-bold red--text"
                        text
                        @click="warningDelete = false; cancelBooking(getDataSelectedDetails); "
                    >
                        YES
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Success Cancel -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogSuccess"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 success--text">
                        <v-icon class="icon-text success--text">mdi-check-circle</v-icon>
                        Success!
                    </v-card-title>
                    <v-card-text class="blackk--text">This car booking is succesfully delete in system.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogSuccess = false; dialogDisplayDetails = false; valueAll = 'ALL'; loadDataTable(); whatFilterValueApproval = 'ALL'; whatFilterValueMileage = 'ALL';"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Error -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogError"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Failed!
                    </v-card-title>
                    <v-card-text class="blackk--text">Something went wrong. Please try again.</v-card-text>
                    <br>
                    <v-card-text>
                        <small class="primary--text">
                            <span class="red--text text-bold">**</span> If the error still occurs, please contact HR.
                        </small> 
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogError = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>
        
    </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';
import Papa from "papaparse";

export default {
    
    data: () => ({

        dataForExport: [],

        loadingDataTable: true,
        loadingText: "", //Data is Loading... Please wait
        dialogSuccess: false,
        dialogError: false,
        warningDelete: false,
        getDataSelectedDetails: null,
        xlehCancelBtn: true,

        // Data Table
        searchDtCarBook: '',
        headers: [],
        allDataTemp: [],
        allDataTemp2: [],
        allData: [],
        searchObj: {},
        multiSearch: [],
        headerCarBooking: [
            {text: "Date Applied", value: "dateApplied"},
            {text: "Car Type", value: "carType"},
            {text: "Event/Project Title", value: "eventTitle"},
            {text: "Start Date Time", value: "startDateTime"},
            {text: "End Date Time", value: "endDateTime"},
            {text: "Status Mileage Update", value: "statusMileageUpdated"},
            {text: "Status Approval", value: "status"},
            {text: "Detail", value: "detailsAll", sortable: false},
        ],
        bodyCarBooking: [],

        // Filter
        itemsFilterApproval: [
            "ALL",
            "(NO LONGER IN RECORD)",
            "(NO NEED APPROVAL)",
            // "EZZEDIN BIN USIR",
            "NURULFIDA KHUZAIEN BINTI AHMAD",
            "NUR LIYANA BINTI SULAIMAN",
            "NUR SYAFIQAH AINA BINTI MOHD ZUKERI",
        ],
        itemsFilterStatus: [
            "ALL",
            "UPDATED",
            "NOT UPDATE",
            "WAITING",
            "CANCEL",
        ],
        filterApproval: "ALL",
        filterStatusMil: "ALL",
        valueAll: "ALL",
        whatFilterValueApproval: "ALL",
        whatFilterValueMileage: "ALL",

        // Display Details
        displayDetails: [
            {key: "Event Car", value: "", icon: "mdi-car-select"},    
            {key: "Date Applied", value: "", icon: "mdi-calendar-cursor"},    
            {key: "User Applied", value: "", icon: "mdi-account"},    
            {key: "User Contact", value: "", icon: "mdi-phone"},    
            {key: "Start Date", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date", value: "", icon: "mdi-calendar-end"},    
            {key: "Car", value: "", icon: "mdi-car-multiple"},    
            {key: "Event Name", value: "", icon: "mdi-pin-outline"},    
            {key: "Event Details", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Latest Mileage", value: "", icon: "mdi-speedometer"},    
            {key: "Mileage Data Image", path: "", icon: "mdi-speedometer"},    
            {key: "Status", value: "", icon: "mdi-check-decagram-outline"},    
            // {key: "Action", value: "", icon: ""},    
        ],
        dialogDisplayDetails: false,
        detailData: null,

        // Approve OR Reject
        // listCar: [
        //     {id: 1, label: "Perodua Bezza (Red)", colorCheckbox: "primary", calendarColor: "red", plate: "SSA 2469 A"},
        //     {id: 2, label: "Perodua Bezza (Blue)", colorCheckbox: "primary", calendarColor: "blue", plate: "SSA 2369 A"},
        //     {id: 3, label: "Perodua Axia (Blue)", colorCheckbox: "primary", calendarColor: "green", plate: "ABC 123"},
        //     {id: 4, label: "Ford Ranger (Blue)", colorCheckbox: "primary", calendarColor: "purple", plate: "ABC 456"},
        //     {id: 5, label: "Isuzu DMax (Grey)", colorCheckbox: "primary", calendarColor: "grey", plate: "ABC 789"},
        // ],
        
        jsonEditBookCar: {
            idcartype: "",
            startdate: "",
            enddate: "",
            status: "",
            screenshot: "",
            name: "",
            detailevent: "",
            mileagecar: "",
        },

        myId: null,
        myName: null,
        onlyMeCan: null,

    }),
    methods: {

        // General
        load(){
            // console.log("Load");
            // console.log(this);
        },

        myPersonalData(){

            this.myId = this.$store.getters.user.idEmp;

            axios.get(process.env.VUE_APP_API_URL + "employees/all",
            {
                headers: {
                    "Authorization": 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);

                let data = response.data;
                for(let i in data){
                    if (data[i].idemp == this.myId) {
                        this.myName = data[i].name;
                    }
                }
            })
            .catch(e => {
                console.log(e.response);
            })

        },

        // Data Table Section
        loadDataTable() {

            const d = new Date();
            let thisYear = d.getFullYear();
            let prevD = d.setFullYear(d.getFullYear()-1);
            let prevYear = new Date(prevD).getFullYear();
            

            this.loadingDataTable = true;
            this.loadingText = "Data is Loading... Please wait";
            this.headers = [];
            this.allData = [];


            this.headers = this.headerCarBooking

            axios.get(process.env.VUE_APP_API_URL + 'car/allbookings?startdt='+prevYear+'-01-01T00:00:00&enddt='+thisYear+'-12-31T23:59:59&withrejected=1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let data = response.data
                // console.log(data);

                for(let i in data){
                    data[i]["dateApplied"] = this.convertDateTime(data[i]["dateapplied"]); 
                    // data[i]["carType"] =  this.capitalizeWords(data[i]["carType"].split("(")[0])+ "(" + data[i]["carType"].split("(")[1];
                    data[i]["eventTitle"] =  data[i]["name"];
                    data[i]["startDateTime"] = this.convertDateTime(data[i]["start"]); 
                    data[i]["endDateTime"] = this.convertDateTime(data[i]["end"]);
                    data[i]["detailsEvents"] =  data[i]["detailEvent"];
                    data[i]["detailsAll"] = "mdi-file-document";
                    data[i]["statusApproval"] = data[i]["status"];
                    data[i]["statusApproval"] = data[i]["status"];

                    for(let x in this.globalListCar){
                        if (data[i]['carType'].toLowerCase() == this.globalListCar[x].label.toLowerCase()) {
                            data[i]['approvalUser'] = this.globalListCar[x].userApproval;
                        }
                    }
                    

                    // Filter/Categorize Status
                    if (data[i]["status"] == "REJECTED") {
                        data[i]["statusMileageUpdated"] = "Reject";
                    }
                    else{

                        if(Date.parse(new Date(data[i]["end"])) - Date.parse(new Date()) < 0)
                        {
                            if (data[i]["mileageCar"] != "" && data[i]["mileageCar"] != null) {
                                data[i]["statusMileageUpdated"] = "YES";
                            }
                            else{
                                data[i]["statusMileageUpdated"] = "NO";
                            }
                        }
                        else{
                            data[i]["statusMileageUpdated"] = "InUse";
                        }

                    }
                    
                    
                }



                this.allData = data;
                this.allDataTemp = data;
                this.allDataTemp2 = data;
                this.loadingDataTable = false;
                this.loadingText = "";
                
            })
            .catch(error => {
                console.log(error.response);
                this.loadingDataTable = false;
                this.loadingText = "";
            })
        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            this.dataForExport = items;

            items.sort((a, b) => {
                if (index[0]=='dateApplied' || index[0]=='startDateTime' || index[0]=='endDateTime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        controlColorFont(status){
            if (status == "APPROVED") {
                return "white--text text-bold" 
            }
            else if (status == "REJECTED") {
                return "white--text text-bold" 
            }
            else if (status == "PENDING") {
                return "white--text text-bold" 
            }
            else if(status == "N/A"){
                return "blackk--text text-bold" 
            }
        },
        
        controlBgColor(status){
            if (status == "APPROVED") {
                return "success" 
            }
            else if (status == "REJECTED") {
                return "reject" 
            }
            else if (status == "PENDING") {
                return "pending" 
            }
            else if(status == "N/A"){
                // return "white" 
            }
        },

        isDisable(index) {
            // console.log(index);
            if (index == 5 || index == 7) {
                return true;
            }
            else{
                return false;
            }
        },

        filterTable(item,type){
            // console.log(item);

            
            if (type == "userApproval") {
                this.filterApproval = item;
            }
            else if (type == "statusMil") {
                this.filterStatusMil = item;
            }

            let temp = [];
            for(let i in this.allDataTemp){
                if (this.filterApproval == "ALL" && this.filterStatusMil == "ALL") {
                    // this.loadDataTable();
                    this.allData = [];
                    this.allData = this.allDataTemp2;
                }
                else if (this.filterApproval != "ALL"  && this.filterStatusMil == "ALL") {
                    if (this.allDataTemp[i]['approvalUser'].toLowerCase() == this.filterApproval.toLowerCase()) {
                        temp.push(this.allDataTemp[i])
                        // console.log(this.allDataTemp[i]['approvalUser']);
                    }

                    this.allData = [];
                    this.allData = temp;
                }
                else if (this.filterApproval == "ALL"  && this.filterStatusMil != "ALL") {
                    if (this.filterStatusMil == "UPDATED") {
                        if (this.allDataTemp[i]['statusMileageUpdated'] == "YES") {
                            temp.push(this.allDataTemp[i]);
                        }
                    }
                    else if (this.filterStatusMil == "NOT UPDATE") {
                        if (this.allDataTemp[i]['statusMileageUpdated'] == "NO") {
                            temp.push(this.allDataTemp[i]);
                        }
                    }
                    else if (this.filterStatusMil == "WAITING") {
                        if (this.allDataTemp[i]['statusMileageUpdated'] == "InUse") {
                            temp.push(this.allDataTemp[i]);
                        }
                    }
                    else if (this.filterStatusMil == "CANCEL") {
                        if (this.allDataTemp[i]['statusMileageUpdated'] == "Reject") {
                            temp.push(this.allDataTemp[i]);
                        }
                    }
                    
                    this.allData = [];
                    this.allData = temp;
                }
                else if (this.filterApproval != "ALL"  && this.filterStatusMil != "ALL") {
                    if (this.allDataTemp[i]['approvalUser'].toLowerCase() == this.filterApproval.toLowerCase()) {
                        if (this.filterStatusMil == "UPDATED") {
                            if (this.allDataTemp[i]['statusMileageUpdated'] == "YES") {
                                temp.push(this.allDataTemp[i]);
                            }
                        }
                        else if (this.filterStatusMil == "NOT UPDATE") {
                            if (this.allDataTemp[i]['statusMileageUpdated'] == "NO") {
                                temp.push(this.allDataTemp[i]);
                            }
                        }
                        else if (this.filterStatusMil == "WAITING") {
                            if (this.allDataTemp[i]['statusMileageUpdated'] == "InUse") {
                                temp.push(this.allDataTemp[i]);
                            }
                        }
                        else if (this.filterStatusMil == "CANCEL") {
                            if (this.allDataTemp[i]['statusMileageUpdated'] == "Reject") {
                                temp.push(this.allDataTemp[i]);
                            }
                        }
                    }
                    
                    this.allData = [];
                    this.allData = temp;
                }
            }

            


            
        },

        openFileDoc(item){
            // console.log(item);

            // Control Cancel Button Sape Boleh Cancel
            // this.xlehCancelBtn = true;

            if (new Date() - new Date(item.end) > 0) {
                this.xlehCancelBtn = true;
            }
            else{

                if (item.status == "REJECTED") {
                   this.xlehCancelBtn = true; 
                }
                else{
                    if (this.$store.getters.user.name == item.approvalUser.toUpperCase() || this.onlyAdmin) {
                        this.xlehCancelBtn = false;
                    }
                    else{
                        this.xlehCancelBtn = true;
                    }
                }
                
            }

            
            

            for (let i = 0; i < this.displayDetails.length; i++) {
                this.displayDetails[i].value = "";
            }
            
            if (item.status == "N/A") {
                this.displayDetails[0].value = "Daily Car";
            }
            else{
                this.displayDetails[0].value = "Project Car";
            }
            this.displayDetails[1].value = this.convertDateTime(item.dateapplied);
            this.displayDetails[2].value = item.userApply;

            if (item.userContact != null && item.userContact != "") {
                this.displayDetails[3].value = item.userContact;
            }
            else{
                this.displayDetails[3].value = "-";
            }
            
            this.displayDetails[4].value = this.convertDateTime(item.start);
            this.displayDetails[5].value = this.convertDateTime(item.end);
            this.displayDetails[6].value = item.carType;
            this.displayDetails[7].value = item.name;
            
            
            if (item.detailEvent != null && item.detailEvent != "") {
               this.displayDetails[8].value = item.detailEvent;
            }
            else{
                this.displayDetails[8].value = "-";
            }

            if (item.mileageCar != null && item.mileageCar != "") {
               this.displayDetails[9].value = item.mileageCar + " KM"; 
            }
            else{
                this.displayDetails[9].value = "-";
            }

            if (item.screenshot != null && item.screenshot != "") {
               this.displayDetails[10].path = item.screenshot;
            }
            else{
                this.displayDetails[10].path = null;
            }
            
            this.displayDetails[11].value = item.status;

            // Untuk collect data selection
            this.detailData = item;

            this.dialogDisplayDetails = true;
        },

        openWarning(data){
            this.warningDelete = true;
            this.getDataSelectedDetails = data;
        },

        cancelBooking(data){

            // console.log(data);

            let idCar = null;
            for(let i in this.globalListCar){
                // console.log(this.globalListCar[i].label);
                if (this.globalListCar[i].label.toUpperCase() == data.carType) {
                    idCar = this.globalListCar[i].id
                }
            }

            // console.log(idCar);

            axios.get(process.env.VUE_APP_API_URL + "car/delbookcar?idcartype="+idCar+"&startdate="+data.start+"&enddate="+data.end,
            {
                headers: {
                    "Authorization": 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogSuccess = true;
                bus.$emit('changeIt', 'loadNotify');
                bus.$emit('changeIt', 'loadCarCalendarAdmin');
            })
            .catch(e => {
                console.log(e.response);
                this.dialogSuccess = false;
                this.dialogError = true;
            })

        },

        rejectPending(data){
            // console.log(data);
            // console.log(this.globalListCar);

            for(let i in this.globalListCar){
                if (this.globalListCar[i].label.toUpperCase() == data.carType) {
                    this.jsonEditBookCar.idcartype =  this.globalListCar[i].id;
                    // console.log(this.globalListCar[i].id);
                }
            }
            this.jsonEditBookCar.startdate = data.start;
            this.jsonEditBookCar.enddate = data.end;
            this.jsonEditBookCar.status = "REJECTED";
            this.jsonEditBookCar.screenshot = data.screenshot;
            this.jsonEditBookCar.name = data.name;
            this.jsonEditBookCar.detailevent = data.detailEvent;
            this.jsonEditBookCar.mileagecar = data.mileageCar;

            let updateJson = JSON.stringify(this.jsonEditBookCar);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL + "car/editbookcar",
            updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogDisplayDetails = false;
                this.loadDataTable();
                bus.$emit('changeIt', 'loadNotify');
            })
            .catch(e => {
                console.log(e.response);
            })

        },

        approvePending(data){
            console.log(this.globalListCar);
            for(let i in this.globalListCar){
                if (this.globalListCar[i].label.toUpperCase() == data.carType) {
                    this.jsonEditBookCar.idcartype =  this.globalListCar[i].id;
                    // console.log(this.globalListCar[i].id);
                }
            }
            this.jsonEditBookCar.startdate = data.start;
            this.jsonEditBookCar.enddate = data.end;
            this.jsonEditBookCar.status = "APPROVED";
            this.jsonEditBookCar.screenshot = data.screenshot;
            this.jsonEditBookCar.name = data.name;
            this.jsonEditBookCar.detailevent = data.detailEvent;
            this.jsonEditBookCar.mileagecar = data.mileageCar;

            let updateJson = JSON.stringify(this.jsonEditBookCar);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL + "car/editbookcar",
            updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogDisplayDetails = false;
                this.loadDataTable();
                bus.$emit('changeIt', 'loadNotify');
            })
            .catch(e => {
                console.log(e.response);
            })
        },

        exportExcel(){

            // console.log(this.dataForExport);

            let keys = [
                "dateApplied",
                "carType",
                "eventTitle",
                "startDateTime",
                "endDateTime",
                "statusMileageUpdated",
                "statusApproval",
                "userApply",
                "userContact",
            ],
            resultAfterFilter = this.dataForExport.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

            let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");

            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'List of Booking Meeting Room.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    },
    mounted(){
        this.load();
        this.loadDataTable();
        this.myPersonalData();
        // console.log(this.$store.getters.user.idEmp);
    },
    computed: {
        filteredData(){
            if (this.searchObj) {
                return this.allData.filter(item => {
                    return Object.entries(this.searchObj).every(([key, value]) => {
                        return (item[key] ||'').toLowerCase().includes(value.toLowerCase())
                    })
                })
            }
            else{
                return this.allData
            } 
        },
    }
}

</script>

<style lang="scss">
@import '~scss/main';

// Custom default
// .sizingTable > .v-data-table__wrapper{
//     max-height: calc(100vh - 370px);
// } Sebab laptop Kak Mi hancur viewer (over max height)

// .table-card{
//     height: calc(100vh - 240px);
// }

</style>