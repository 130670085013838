<template>
    <v-container>
        
        <!-- Vue Calendar -->
        <v-row class="fill-height">
            <v-col>
            <v-sheet class="calendarSizing" height="65">
                <v-toolbar
                flat
                >
                <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
                >
                    Today
                </v-btn>
                <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
                >
                    <v-icon small>
                    mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
                >
                    <v-icon small>
                    mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                bottom
                right
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>
                        mdi-menu-down
                        </v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                    <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
                <v-calendar
                :available-dates='{ start: new Date(), end: null }'
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="loadDataCalendar"
                ></v-calendar>
                <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
                max-width="480px"
                >
                    <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                    >
                        <v-toolbar
                        :color="selectedEvent.color"
                        dark
                        >
                        <v-btn icon @click="viewData(selectedEvent)">
                            <v-icon>mdi-clipboard-text-play-outline</v-icon>
                        </v-btn>
                        <v-toolbar-title class="mr-5" dense collapse v-html="selectedEvent.displayHeaderDetails"></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="selectedOpen = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-toolbar>
                        <v-card-text >
                            <v-simple-table dense>
                                <template v-slot:default>
                                <tbody>
                                    <tr v-if="$vuetify.breakpoint.lgAndUp">
                                        <td colspan="2">

                                            <span v-for="(item, i) in globalListCar" :key="i">
                                                <!-- <span v-if="item.id === selectedEvent.idmeetingroom"> -->
                                                    <v-img
                                                    v-if="item.calendarColor === selectedEvent.color"
                                                    class="my-3"
                                                    height="150"
                                                    width="350"
                                                    :src="item.src"
                                                    >
                                                    </v-img>
                                                <!-- </span> -->
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-calendar-clock</v-icon>
                                            Start Date
                                            <!-- <v-icon small>mdi-transfer-right</v-icon> -->
                                        </td>
                                        <td>
                                            <span v-html="convertDateTime(selectedEvent.start)"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-calendar-clock</v-icon>
                                            End Date
                                            
                                        </td>
                                        <td>
                                            <span v-html="convertDateTime(selectedEvent.end)"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-account</v-icon>
                                            User
                                        </td>
                                        <td>
                                            <span v-html="selectedEvent.userApply"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-cellphone-sound</v-icon>
                                            Contact
                                        </td>
                                        <td>
                                            <span v-if="selectedEvent.userContact != null" v-html="selectedEvent.userContact"></span>
                                            <span v-if="selectedEvent.userContact == null">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-card-text-outline</v-icon>
                                            Event
                                        </td>
                                        <td>
                                            <span v-html="selectedEvent.name"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-speedometer</v-icon>
                                            Mileage
                                        </td>
                                        <td>
                                            <span v-if="selectedEvent.mileageCar != null" v-html="selectedEvent.mileageCar"></span>
                                            <span v-if="selectedEvent.mileageCar == null">-</span>
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        <!-- <v-card-actions>
                        <v-btn
                        class="text-bold"
                        text
                        color="primary"
                        @click="selectedOpen = false"
                        >
                            Cancel
                        </v-btn>
                        </v-card-actions> -->
                    </v-card>
                </v-menu>
            </v-sheet>
            </v-col>
        </v-row>
        
        <!-- Label Coloring Car (Desktop)-->
        <div
        style="display:flex;"
        >
            <v-container>
                <v-row>
                   <span v-for="item in globalListCar" :key="item.calendarColor" style="margin-right:30px;">
                        <v-col>
                            <small>
                                <v-icon
                                :class="item.calendarColor+'--text mr-2'"
                                >
                                    <!-- {{item.icon}} -->
                                    mdi-car-side
                                </v-icon>
                                <span>{{item.label}}</span>
                            </small>
                        </v-col>
                    </span> 
                </v-row>
            </v-container>
        </div>

    

        <template>
            <v-dialog
            v-model="singleOptionDayEvent"
            width="200"
            scrollable
            >
            <v-card>
                <v-card-title class="justify-center title-bar-modul">
                    <h4>Day Event</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col
                            align="center"
                        >
                            <v-btn
                                color="primary"
                                outlined
                                @click="zoomDaySelected"
                            >
                                Day Event
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-dialog>
        </template>


        <!-- Popup View Details -->
        <template>
            <v-dialog
            v-model="dialogViewDataCarBook"
            persistent
            scrollable
            max-height="10px"
            max-width="600px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Details Car Booking</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>

                        <v-row>
                            <v-col>
                                <v-simple-table class="table-details-info">
                                    <template v-slot:default>
                                    <tbody>
                                        <tr
                                        v-for="item in displayDetails"
                                        :key="item.name"
                                        >
                                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                                <v-icon v-if="$vuetify.breakpoint.lgAndUp" class="icon-text blackIcon--text" small>
                                                    {{ item.icon }}</v-icon>{{ item.key }}
                                            </td>
                                            <td>

                                                <span v-if="item.value != 'PENDING' && item.value != 'APPROVED'  && item.attribute != 'screenshot'">
                                                    {{ item.value }}
                                                </span>

                                                <v-chip
                                                v-if="item.value == 'APPROVED' && item.attribute != 'screenshot'"
                                                color="success"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                
                                                <v-chip
                                                v-if="item.value == 'PENDING' && item.attribute != 'screenshot'"
                                                color="pending"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                
                                                <span v-if="item.value != 'PENDING' && item.attribute == 'screenshot'">
                                                    <a v-if="item.value != null" :href="item.value" target="_blank">
                                                        <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                                        <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                                    </a>
                                                    <a v-if="item.value == null" style="cursor:default;">
                                                        <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                                        <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                                    </a> 
                                                </span>
                                                    
                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        
                    </v-container>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    class="text-bold"
                    color="primary"
                    text
                    @click="dialogViewDataCarBook = false;"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </template>

        <!-- Warning Blank Field Form -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogWarningBlankInput"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text" color="red">mdi-alert-circle</v-icon>
                        Warning!
                    </v-card-title>
                    <v-card-text class="blackk--text">Please fill out all of required field.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="dialogWarningBlankInput = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

    </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    
    data: () => ({

        // Calendar
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        storeDataDisplayCalendar: [],
        dataDummy: [
            {
                name: 'TNB Site',
                start: '2022-01-23 T09:30:00',
                end: '2022-01-23 T16:30:00',
                color: 'purple',
                timed: true,
                carType: 'Ford Ranger (Blue)',
                detailEvent: 'Survey - Bangi Seksyen 9',
                mileageCar: '',
                imageMileage: null,
                userApply: 'Furqan Ahmad',
                userContact: '018-0000000',
                idUser: 'GIS321'
            },
            {
                name: 'Bantuan Mangsa Banjir',
                start: '2022-01-21 T13:30:00',
                end: '2022-01-21 T18:00:00',
                color: 'blue',
                timed: true,
                carType: 'Perodua Bezza (Blue)',
                detailEvent: 'Bantu bersihkan staf-staf serasi yang terkesan dengan bencana banjir',
                mileageCar: '',
                imageMileage: null,
                userApply: 'Firdaus Arif',
                userContact: '011-1111000',
                idUser: 'SIS122'
            }
        ],
        dataDummy2: [
            {
                name: "PERODUA BEZZA",
                start: "2022-01-17 T00:00:00",
                end: "2022-01-17 T13:00:00",
                color: "red",
                timed: "true",
            },
            {
                name: "PERODUA BEZZA",
                start: "2022-01-17T00:00:00",
                end: "2022-01-17T13:00:00",
                color: "blue",
                timed: "true",
            }
        ],
        dialogAddDateCarBook: false,
        // listColorCar: [
        //     {car: "Perodua Bezza (Red - SSA2469A)", class: "ssa2469a", icon: "mdi-car-side"},
        //     {car: "Perodua Bezza (Blue - SSA2369A)", class: "ssa2369a", icon: "mdi-car-side"},
        //     {car: "Perodua Axia (Blue - VFP8523)", class: "vfp8523", icon: "mdi-car-side"},
        //     {car: "Ford Ranger (Blue - SS6955W)", class: "ss6955w", icon: "mdi-car-side"},
        //     {car: "Isuzu DMax  (Grey - BME8395)", class: "bme8395", icon: "mdi-car-side"},
        //     {car: "Perodua Axia (Silver - VP2514)", class: "vp2514", icon: "mdi-car-side"},
        //     {car: "Perodua Axia (White - DDA413)", class: "dda413", icon: "mdi-car-side"},
        //     {car: "Perodua Axia (White - PMS8914)", class: "pms8914", icon: "mdi-car-side"},
        // ],
    

        // Object Data Form
        objectPostDataForm: {
            eventCar: '',
            selectedCarType: '',
            lebelColorType: '',
            eventName: '',
            eventDescribe: '',
            userApply: '',
            userContact: '',
            mileageTotal: '',
            imageMileage: null
        },

        // Store for combination date and time
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        startTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        endTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),


        // Set rule field is required
        rules: {
          eventName: [val => (val || '').length > 0 || 'This field is required'],
          startTime: [val => (val || '').length > 0 || 'Start time is required'],
          endTime: [val => (val || '').length > 0 || 'End Time is required'],
        },


        // Engines/Dialog/Listing related to data form
        radios1: null,
        radioCarEvent: [
            {label: "Daily Car", color: "primary", value: "dailyCar"},
            {label: "Project Car", color: "primary", value: "projectCar"},
        ],    
        menuStartDt: false,
        modalStartTime: false,
        datePickerStartToday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuEndtDt: false,
        modalEndTime: false,
        radios2: null,
        // listCar: [
        //     {label: "Perodua Bezza (Red)", colorCheckbox: "primary", calendarColor: "red", plate: "SSA 2469 A"},
        //     {label: "Perodua Bezza (Blue)", colorCheckbox: "primary", calendarColor: "blue", plate: "SSA 2369 A"},
        //     {label: "Perodua Axia (Blue)", colorCheckbox: "primary", calendarColor: "green", plate: "ABC 123"},
        //     {label: "Ford Ranger  (Blue)", colorCheckbox: "primary", calendarColor: "purple", plate: "ABC 456"},
        //     {label: "Isuzu D-Max  (Grey)", colorCheckbox: "primary", calendarColor: "grey", plate: "ABC 789"},
        // ],

        radioCarType: [],
        optionDayEvent: false,
        singleOptionDayEvent: false,
        daySelected: null,
        imageMil: null,
        visibleCar: false,
        
        // Dialog
        dialogWarningBlankInput: false,
        dialogViewDataCarBook: false,

        // View Data
        displayDetails: [
            {key: "Event Car", attribute: "eventCar", value: "", icon: "mdi-car-select"},    
            {key: "Date Applied", attribute: "dateapplied", value: "", icon: "mdi-calendar-cursor"},
            {key: "User Applied", attribute: "userApply", value: "", icon: "mdi-account"},    
            {key: "User Contact", attribute: "userContact", value: "", icon: "mdi-phone"},     
            {key: "Start Date Time", attribute: "start", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date Time", attribute: "end", value: "", icon: "mdi-calendar-end"},    
            {key: "Car", attribute: "carType", value: "", icon: "mdi-car-multiple"},    
            {key: "Event/Project Name", attribute: "name", value: "", icon: "mdi-pin-outline"},    
            {key: "Event/Project Details", attribute: "detailEvent", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Latest Mileage", attribute: "mileageCar", value: "", icon: "mdi-speedometer"},    
            {key: "Mileage Data Image", attribute: "screenshot", value: "", icon: "mdi-speedometer"},    
            {key: "Status", attribute: "status", value: "", icon: "mdi-check-decagram-outline"},    
        ],

    }),
    methods: {

        // General
        load(){
            // console.log("Load");
        },


        // Calendar Vuetify Section
        viewDay ({ date }) {

            // console.log(Date.parse(date) + " is " + new Date(date));
            // console.log(Date.parse(new Date()) + " is " + new Date());

            // Set Value input/field in Form Car Book
            this.daySelected = date;
            this.startDate = date;
            this.endDate = date;
            this.startTime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5);
            this.endTime = this.startTime;
            this.loadListAvailableCar(this.startDate,this.endDate,this.startTime,this.endTime);
            this.objectPostDataForm.userApply = this.$store.getters.user.name;
            this.objectPostDataForm.userContact = this.$store.getters.user.phone;
            
            
            // kena set date clicked with current time.. By default selected date time = 08:00:00 Malaysia
            // let date2 = date + "T" + (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 8);

            // // If past day, jangan open popup car booking
            // if(Date.parse(new Date(date2)) - Date.parse(new Date()) < 0)
            // {

            //     this.singleOptionDayEvent = true;

            //     // Check kalau today or not 
            //     // if (new Date(date).toISOString().substr(0, 10) == new Date().toISOString().substr(0, 10)) {

            //     //     console.log("today?");
            //     //     this.optionDayEvent = true;
            //     // }
            //     // else{
            //     //     this.singleOptionDayEvent = true;
            //     //     console.log("pastt??");
            //     // }

            // }
            // else{
            //     this.singleOptionDayEvent = true;
            // }

            // Uncomment below code for zoom event day
            this.focus = date
            this.type = 'day'

        },
        zoomDaySelected(){
            this.focus = this.daySelected;
            this.type = 'day'
            this.optionDayEvent = false
            this.singleOptionDayEvent = false
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {

            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target

                let myInterval = setInterval(() => {
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                    clearInterval(myInterval);
                }, 500);
                
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        loadDataCalendar () {

            const d = new Date();
            let thisYear = d.getFullYear();
            let prevD = d.setFullYear(d.getFullYear()-1);
            let prevYear = new Date(prevD).getFullYear();

            axios.get(process.env.VUE_APP_API_URL + 'car/allbookings?startdt='+prevYear+'-01-01T00:00:00&enddt='+thisYear+'-12-31T23:59:59', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let storeDat = [];
                let data = response.data
                // console.log(data);
                data.forEach(function(res){
                    res["color"] = res["color"].toLowerCase();
                    res["displayHeaderDetails"] = res["carType"].split(' (')[0] + ' (' + res["carType"].split('- ')[1];
                    if (res["mileageCar"] != null) {
                        res["mileageCar"] = res["mileageCar"] + " KM";
                    }
                    storeDat.push(res);
                });

                this.events = storeDat;
                // console.log(this.events);
                
            })
            .catch(error => {
                console.log(error.response);
            })

        },

        loadListAvailableCar(startDt,endDt,startT,endT){

            this.radioCarType = [];

            if (this.radioCarType.length == 0) {
                this.visibleCar = true;
            }

            axios.get(process.env.VUE_APP_API_URL + 'car/cekcars?startdt='+startDt+'T'+startT+':00&enddt='+endDt+'T'+endT+':00', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let data = response.data
                for (let i = 0; i < data.length; i++) {
                    for (let x = 0; x < this.globalListCar.length; x++) {
                        if (data[i].plateno == this.globalListCar[x].plate) {
                            data[i]["label"] = this.globalListCar[x].label;
                            data[i]["calendarColor"] = this.globalListCar[x].calendarColor;
                            data[i]["colorCheckbox"] = this.globalListCar[x].colorCheckbox;
                        }
                    }
                }

                this.radioCarType = data;
                if (this.radioCarType.length != 0) {
                    this.visibleCar = false;
                }
                
            })
            .catch(error => {
                console.log(error.response);
            })

        },


        // Process Fill Form Car Booking & Submit
        cancelFillBooking(){
            this.$refs.form.reset();
            this.dialogAddDateCarBook = false
            this.openSummaryProfile();
        },

        eventCar(value){
            this.objectPostDataForm.eventCar = value
        },

        checkAvailableCar(){

            if (Date.parse(this.startDate) > Date.parse(this.endDate)) {
                this.endDate = this.startDate;
            }

            this.loadListAvailableCar(this.startDate,this.endDate,this.startTime,this.endTime)
        },

        chooseCar: function(car,colorLabel){
            this.objectPostDataForm.selectedCarType = car;
            this.objectPostDataForm.lebelColorType = colorLabel;

            // console.log(this.objectPostDataForm);
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        handleFileUpload(e){
            this.objectPostDataForm.imageMileage = e;
        },

        viewData(data){

            // console.log(data);

            // Json just for display
            let keyData = Object.keys(data)
            for(let i in this.displayDetails){
                for(let x in keyData){
                    if (this.displayDetails[i].attribute == keyData[x]) {
                        
                        if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                            this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                        }
                        else if (keyData[x] == "userContact") {
                            if (data[keyData[x]] != null && data[keyData[x]] != "") {
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                            else{
                                this.displayDetails[i].value = "-";
                            }
                        }
                        else if (keyData[x] == "detailEvent") {
                            if (data[keyData[x]] != null  && data[keyData[x]] != "") {
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                            else{
                                this.displayDetails[i].value = "-";
                            }
                        }
                        else if (keyData[x] == "mileageCar") {
                            if (data[keyData[x]] != null && data[keyData[x]] != "") {
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                            else{
                                this.displayDetails[i].value = "-";
                            }
                        }
                        else{
                            this.displayDetails[i].value = data[keyData[x]];
                        }
                    }
                }

                if (this.displayDetails[i].attribute == "eventCar") {
                    if (data.status == "N/A") {
                        this.displayDetails[i].value = "Daily Car"
                    }
                    else{
                        this.displayDetails[i].value = "Project Car"
                    }
                }

            }
            
            this.dialogViewDataCarBook = true;
        }

    },
    mounted(){
        this.load();
        this.objectPostDataForm.userApply = this.$store.getters.user.name;
        this.objectPostDataForm.userContact = this.$store.getters.user.phone;
    },
    created (){
        bus.$on('changeIt', (data) => {
            
            // console.log(data);

            if (data == "loadCarCalendarAdmin") {
                this.loadDataCalendar();
            }

           
        })
    }
}

</script>

<style lang="scss">
@import '~scss/main';



</style>