<template>
    <v-container> 

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-car-cog</v-icon>
            Admin - Car Booking
        </v-card-title>

        <!-- Looping List Tab -->
        <v-tabs
        v-model="tabs"
        show-arrows="mobile"
        >
            <v-tab v-for="item in tabsCarBooking" :key="item.tab" class="tab-icon">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.tabName }}
            </v-tab>
        </v-tabs>

        <!-- Container Tabs -->
        <v-tabs-items v-model="tabs">

            <!-- Tab Inbox -->
            <v-tab-item class="container-tab-modul">

                <inbox-datatable></inbox-datatable>

            </v-tab-item>

            <!-- Tab Calendar -->
            <v-tab-item class="container-tab-modul">
                
                <calendar-calendar></calendar-calendar>

            </v-tab-item>

            <!-- Tab Car Info -->
            <v-tab-item class="container-tab-modul">
                
                <car-info></car-info>

            </v-tab-item>
            

        </v-tabs-items>

    </v-container>
</template>

<script>
import dtSysAdminDtCarBooking from '@/components/systemAdmin/carBooking/Datatable'
import dtSysAdminCalCarBooking from '@/components/systemAdmin/carBooking/Calendar'
import dtSysAdminInfoCarBooking from '@/components/systemAdmin/carBooking/CarInfo'
export default {
    components: {
        'inbox-datatable': dtSysAdminDtCarBooking,
        'calendar-calendar': dtSysAdminCalCarBooking,
        'car-info': dtSysAdminInfoCarBooking,
    },
    data: () => ({

        tabs: null,
        tabsCarBooking: [
            {tabName: "Inbox", icon: "mdi-inbox-multiple"},
            {tabName: "Calendar", icon: "mdi-calendar-multiselect"},
            {tabName: "Info", icon: "mdi-car-info"},
        ],


    }),
    methods: {

        // General
        load(){
            // console.log("Load");
        },


    },
    mounted(){
        this.load();
        // console.log(this.$store.getters.user);
        // console.log(this.$store);
    },
    computed:{}
}


</script>

<style lang="scss">
@import '~scss/main';

</style>
